var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row rounded rb-dashboard-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "col dashboard-filters pt-3",
        class: { "d-none": _vm.hideFilters },
      },
      [
        _c(
          "div",
          { staticClass: "mb-3" },
          [
            _c(
              "el-button",
              {
                staticClass: "btn-block",
                attrs: { type: "info", loading: _vm.isLoading },
                on: { click: _vm.updateDashboard },
              },
              [_vm._v(" Apply filters ")]
            ),
          ],
          1
        ),
        _c("date-range-input", {
          staticClass: "rb-dashboard-date-input mb-2",
          attrs: { disabled: _vm.isLoading },
          on: { "date-range-changed": _vm.onDateRangeChanged },
          model: {
            value: _vm.dashboardForm.dateRange,
            callback: function ($$v) {
              _vm.$set(_vm.dashboardForm, "dateRange", $$v)
            },
            expression: "dashboardForm.dateRange",
          },
        }),
        _vm._l(_vm.dashboardInputs.bfilters, function (filter) {
          return _c(
            "div",
            { key: filter.label, staticClass: "d-flex flex-column mb-2" },
            [
              _c(
                "label",
                {
                  staticClass: "rb-text-help text-capitalize",
                  attrs: { for: "grid-custom-filters-input" },
                },
                [_vm._v(" " + _vm._s(filter.label) + " ")]
              ),
              _c(
                "el-select",
                {
                  staticClass: "el-select--transparent",
                  class: { active: _vm.dashboardForm.bfilters[filter.label] },
                  attrs: {
                    name: "bfilters",
                    placeholder: "Select filter",
                    filterable: "",
                    clearable: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.dashboardForm.bfilters[filter.label],
                    callback: function ($$v) {
                      _vm.$set(_vm.dashboardForm.bfilters, filter.label, $$v)
                    },
                    expression: "dashboardForm.bfilters[filter.label]",
                  },
                },
                _vm._l(filter.options, function (option) {
                  return _c("el-option", {
                    key: option,
                    attrs: { label: option, value: option },
                  })
                }),
                1
              ),
            ],
            1
          )
        }),
        _vm._l(_vm.dashboardInputs.filters, function (filter, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "d-flex flex-column mb-2",
              attrs: { label: filter.label },
            },
            [
              _c(
                "label",
                {
                  staticClass: "rb-text-help",
                  attrs: { for: "grid-custom-filters-input" },
                },
                [_vm._v(" " + _vm._s(filter.label) + " ")]
              ),
              _c("rb-select-filter", {
                staticClass: "dashboard-select-filter el-select--transparent",
                class: { active: _vm.dashboardForm.filters[filter.label] },
                attrs: {
                  placeholder: "Find filter",
                  options: filter.options,
                  disabled: _vm.isLoading,
                  clearable: true,
                },
                model: {
                  value: _vm.dashboardForm.filters[filter.label],
                  callback: function ($$v) {
                    _vm.$set(_vm.dashboardForm.filters, filter.label, $$v)
                  },
                  expression: "dashboardForm.filters[filter.label]",
                },
              }),
            ],
            1
          )
        }),
      ],
      2
    ),
    _c("div", { staticClass: "pt-5", class: _vm.dashboardRightClasses }, [
      _c(
        "div",
        { staticClass: "filter-button-wrapper" },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                "open-delay": 300,
                content: _vm.hideFilters ? "Show filters" : "Hide filters",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-button p-1",
                  attrs: { type: "light", size: "mini" },
                  on: { click: () => (_vm.hideFilters = !_vm.hideFilters) },
                },
                [
                  _vm.hideFilters
                    ? _c("menu-right-icon", { attrs: { title: "" } })
                    : _c("menu-left-icon", { attrs: { title: "" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row" },
        [
          _vm.isLoading
            ? _c("loading-square", {
                attrs: { message: "Loading...", overlay: true },
              })
            : _vm._e(),
          !_vm.isDashboardData && !_vm.isLoading
            ? _c("div", { staticClass: "col-12" }, [
                _c("h3", { staticClass: "text-muted" }, [
                  _vm._v(" No Dashboard Data Available. "),
                ]),
              ])
            : _vm._e(),
          _vm.accountLicenseSummary
            ? _c("div", { staticClass: "col-12 mb-4" }, [
                _c("h6", { staticClass: "text-muted mb-3" }, [
                  _vm._v(" Product Summary "),
                ]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _vm._l(_vm.accountLicenseSummary, function (card) {
                      return [
                        _vm.activeServices[card.service]
                          ? _c(
                              "div",
                              {
                                key: card.title,
                                staticClass: "col-sm-6 col-xl-4 col-xl-3 mb-3",
                              },
                              [
                                card.maxCount > 0
                                  ? _c(
                                      "stats-card",
                                      {
                                        attrs: {
                                          title: `${card.count} / ${card.maxCount}`,
                                          "sub-title": card.service,
                                          type: card.type,
                                          icon: card.icon,
                                        },
                                      },
                                      [
                                        _vm.userListingCounts[_vm.userName]
                                          ? _c(
                                              "div",
                                              {
                                                attrs: { slot: "footer" },
                                                slot: "footer",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.userName) +
                                                    " has " +
                                                    _vm._s(
                                                      _vm.userListingCounts[
                                                        _vm.userName
                                                      ][card.service]
                                                    ) +
                                                    " loaded "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _c(
                                      "stats-card",
                                      {
                                        attrs: {
                                          title: `Not Subscribed`,
                                          "sub-title": card.service,
                                          type: card.type,
                                          icon: card.icon,
                                        },
                                      },
                                      [
                                        _vm.userListingCounts[_vm.userName]
                                          ? _c(
                                              "div",
                                              {
                                                attrs: { slot: "footer" },
                                                slot: "footer",
                                              },
                                              [_c("br")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm.activeServices.reviewbox && _vm.reviewboxDashboardSummary
            ? _c("div", { staticClass: "col-12 mb-4" }, [
                _c("h6", { staticClass: "text-muted mb-3" }, [
                  _vm._v(" Reviewbox "),
                ]),
                _c("div", { staticClass: "row" }, [
                  _vm.reviewboxDashboardSummary.ratingsDistributionChart
                    ? _c(
                        "div",
                        { staticClass: "col-sm-6 col-xl-4 mb-3 mb-xl-0" },
                        [
                          _c(
                            "card",
                            { attrs: { type: "chart" } },
                            [
                              _c("template", { slot: "header" }, [
                                _c("h3", { staticClass: "card-category" }, [
                                  _vm._v(" Review Rating Distribution "),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "chart-area" },
                                [
                                  _c("pie-chart", {
                                    attrs: {
                                      "chart-data":
                                        _vm.reviewboxDashboardSummary
                                          .ratingsDistributionChart.chartData,
                                      "extra-options":
                                        _vm.reviewboxDashboardSummary
                                          .ratingsDistributionChart
                                          .extraOptions,
                                      height: 220,
                                    },
                                    on: {
                                      "on-click":
                                        _vm.ratingChartNavigateToReviews,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "col-sm-6 col-xl-4" },
                    [
                      !_vm.reviewboxDashboardSummary.newReviewsChart
                        ? _c("div", [_vm._v(" No review rating data. ")])
                        : _c(
                            "card",
                            { attrs: { type: "chart" } },
                            [
                              _c("template", { slot: "header" }, [
                                _c("h3", { staticClass: "card-category" }, [
                                  _vm._v(" Top SKU's by New Reviews "),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "chart-area" },
                                [
                                  _c("bar-chart", {
                                    staticStyle: { height: "100%" },
                                    attrs: {
                                      "chart-data":
                                        _vm.reviewboxDashboardSummary
                                          .newReviewsChart.chartData,
                                      "gradient-colors":
                                        _vm.reviewboxDashboardSummary
                                          .newReviewsChart.gradientColors,
                                      "gradient-stops":
                                        _vm.reviewboxDashboardSummary
                                          .newReviewsChart.gradientStops,
                                      "extra-options":
                                        _vm.reviewboxDashboardSummary
                                          .newReviewsChart.extraOptions,
                                    },
                                    on: {
                                      "on-click":
                                        _vm.reviewChartNavigateToReviews,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                    ],
                    1
                  ),
                  _vm.reviewboxDashboardSummary.listingsSummary
                    ? _c(
                        "div",
                        { staticClass: "col-sm-6 col-xl-4 mb-3 mb-xl-0" },
                        [
                          _c(
                            "card",
                            {
                              attrs: {
                                type: "tasks h-auto",
                                "body-classes": "p-3",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "h3",
                                  { staticClass: "card-category d-inline" },
                                  [_vm._v(" Reviewbox Listings Summary ")]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "table-full-width table-responsive",
                                },
                                [
                                  _c("base-table", {
                                    attrs: {
                                      data: _vm.reviewboxDashboardSummary
                                        .listingsSummary,
                                      "thead-classes": "text-primary",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ row }) {
                                            return [
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: { path: row.url },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text-muted",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                row.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2289606128
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm.activeServices.pricebox && _vm.priceboxDashboardSummary
            ? _c("div", { staticClass: "col-12 mb-4" }, [
                _c("h6", { staticClass: "text-muted mb-3" }, [
                  _vm._v(" Pricebox "),
                ]),
                _c("div", { staticClass: "row" }, [
                  _vm.priceboxDashboardSummary.buyBoxDistributionChart
                    ? _c(
                        "div",
                        { staticClass: "col-sm-6 col-xl-4 mb-3 mb-xl-0" },
                        [
                          _c(
                            "card",
                            {
                              attrs: { type: "chart", "header-classes": "p-0" },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "card-category bg-default p-3 text-white",
                                  },
                                  [_vm._v(" Buy Box Distribution ")]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "chart-area" },
                                [
                                  _c("pie-chart", {
                                    attrs: {
                                      "chart-data":
                                        _vm.priceboxDashboardSummary
                                          .buyBoxDistributionChart.chartData,
                                      "extra-options":
                                        _vm.priceboxDashboardSummary
                                          .buyBoxDistributionChart.extraOptions,
                                      height: 220,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.priceboxDashboardSummary.listingsSummary
                    ? _c(
                        "div",
                        { staticClass: "col-sm-6 col-xl-4 mb-3 mb-xl-0" },
                        [
                          _c(
                            "card",
                            {
                              attrs: {
                                type: "tasks h-auto",
                                "body-classes": "p-3",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "h3",
                                  { staticClass: "card-category d-inline" },
                                  [_vm._v(" Pricebox Listings Summary ")]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "table-full-width table-responsive",
                                },
                                [
                                  _c("base-table", {
                                    attrs: {
                                      data: _vm.priceboxDashboardSummary
                                        .listingsSummary,
                                      "thead-classes": "text-primary",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ row }) {
                                            return [
                                              _c("td", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-muted mb-0",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(row.title) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1766029142
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm.activeServices.copybox && _vm.copyboxDashboardSummary
            ? _c("div", { staticClass: "col-12 mb-4" }, [
                _c("h6", { staticClass: "text-muted mb-3" }, [
                  _vm._v(" Copybox "),
                ]),
                _c("div", { staticClass: "row" }, [
                  _vm.copyboxDashboardSummary.contentTypePieChart
                    ? _c(
                        "div",
                        { staticClass: "col-sm-6 col-xl-4 mb-3 mb-xl-0" },
                        [
                          _c(
                            "card",
                            { attrs: { type: "chart" } },
                            [
                              _c("template", { slot: "header" }, [
                                _c("h3", { staticClass: "card-category" }, [
                                  _vm._v(" Description Types "),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "chart-area" },
                                [
                                  _c("pie-chart", {
                                    attrs: {
                                      "chart-data":
                                        _vm.copyboxDashboardSummary
                                          .contentTypePieChart.chartData,
                                      "extra-options":
                                        _vm.copyboxDashboardSummary
                                          .contentTypePieChart.extraOptions,
                                      height: 220,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "col-sm-6 col-xl-4 mb-3 mb-xl-0" },
                    [
                      !_vm.copyboxDashboardSummary.imageFeatureChart
                        ? _c("div", [_vm._v(" No image count data. ")])
                        : _c(
                            "card",
                            { attrs: { type: "chart" } },
                            [
                              _c("template", { slot: "header" }, [
                                _c("h3", { staticClass: "card-category" }, [
                                  _vm._v(" Image Count Distribution "),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "chart-area" },
                                [
                                  _c("bar-chart", {
                                    staticStyle: { height: "100%" },
                                    attrs: {
                                      "chart-data":
                                        _vm.copyboxDashboardSummary
                                          .imageFeatureChart.chartData,
                                      "gradient-colors":
                                        _vm.copyboxDashboardSummary
                                          .imageFeatureChart.gradientColors,
                                      "gradient-stops":
                                        _vm.copyboxDashboardSummary
                                          .imageFeatureChart.gradientStops,
                                      "extra-options":
                                        _vm.copyboxDashboardSummary
                                          .imageFeatureChart.extraOptions,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-6 col-xl-4" },
                    [
                      !_vm.copyboxDashboardSummary.contentFeatureChart
                        ? _c("div", [_vm._v(" No feature count data. ")])
                        : _c(
                            "card",
                            { attrs: { type: "chart" } },
                            [
                              _c("template", { slot: "header" }, [
                                _c("h3", { staticClass: "card-category" }, [
                                  _vm._v(
                                    " Content Feature Count Distribution "
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "chart-area" },
                                [
                                  _c("bar-chart", {
                                    staticStyle: { height: "100%" },
                                    attrs: {
                                      "chart-data":
                                        _vm.copyboxDashboardSummary
                                          .contentFeatureChart.chartData,
                                      "gradient-colors":
                                        _vm.copyboxDashboardSummary
                                          .contentFeatureChart.gradientColors,
                                      "gradient-stops":
                                        _vm.copyboxDashboardSummary
                                          .contentFeatureChart.gradientStops,
                                      "extra-options":
                                        _vm.copyboxDashboardSummary
                                          .contentFeatureChart.extraOptions,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                    ],
                    1
                  ),
                  _vm.copyboxDashboardSummary.listingsSummary
                    ? _c(
                        "div",
                        { staticClass: "col-sm-6 col-xl-4 mb-3 mb-xl-0" },
                        [
                          _c(
                            "card",
                            {
                              attrs: {
                                type: "tasks h-auto",
                                "body-classes": "p-3",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "h3",
                                  { staticClass: "card-category d-inline" },
                                  [_vm._v(" Copybox Listings Summary ")]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "table-full-width table-responsive",
                                },
                                [
                                  _c("base-table", {
                                    attrs: {
                                      data: _vm.copyboxDashboardSummary
                                        .listingsSummary,
                                      "thead-classes": "text-primary",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ row }) {
                                            return [
                                              _c("td", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "text-muted",
                                                    attrs: { href: row.url },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(row.title) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      503298217
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }